var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "mx-2", attrs: { cols: "1" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { tabindex: "-1", icon: "", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("activity")
                          }
                        }
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-history")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("View History")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.attr.info,
                            expression: "attr.info"
                          }
                        ],
                        attrs: { tabindex: "-1", icon: "", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("info")
                          }
                        }
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-information-outline")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.attr.info))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }