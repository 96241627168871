<template>
  <v-col
    :cols="typeof noFocus === 'undefined' ? 7 : null"
    class="mt-6">
    <v-autocomplete
      v-if="attr.allow_multiple"
      v-model="newValues"
      :label="attr.name"
      :items="keyValues"
      :required="attr.is_required"
      :error-messages="errorMessages"
      item-text="name"
      item-value="id"
      multiple
      chips
      small-chips
      outlined
      dense
      return-object
      :readonly="readonly"
      @change="saveValues">
    </v-autocomplete>
      <v-autocomplete
        v-else
        :value="(attrvalue !== null) ? attrvalue.attr_key_value : ''"
        v-model="newval"
        :label="attr.name"
        :items="keyValues"
        :required="attr.is_required"
        :error-messages="errorMessages"
        item-text="name"
        item-value="id"
        background-color="#fff"
        outlined
        dense
        :readonly="readonly"
        return-object
        @focus="checkTimeSensitive"
        @change="save">
    </v-autocomplete>
  </v-col>
</template>
<script>
// API
import kv from '@/axios/key-values-endpoint'
import attrApi from '@/axios/party-attribute-endpoint'
// Mixins
import { attrsMixin } from '@/mixins/attrs.js'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils.js'
export default {
  name: 'KeyValue',
  data: () => ({
    keyVal: {},
    keyValues: [],
    newval: '',
    newValues: []
  }),
  watch: {
    attrvalues: {
      handler () {
        if (this.allowMultiple) this.initMultiValues()
      },
      deep: true
    }
  },
  mixins: [attrsMixin, displayAlert, utils],
  async created () {
    await this.getKeyValues()
    if (this.attr.allow_multiple && this.attrvalues.length > 0) {
      this.initMultiValues()
    } else {
      if (this.attrvalue) {
        this.newval = this.attrvalue.attr_key_value
      }
    }
  },
  computed: {
    allowMultiple () {
      return this.attr.allow_multiple
    },
    addedValues () {
      if (this.allowMultiple && this.newValues.length > 0) {
        return this.newValues.filter(val => {
          const found = this.attrvalues.find(attrVal => {
            return attrVal.attr_key_value.id === val.id
          })
          return !found
        })
      }
      return []
    },
    removedValues () {
      if (this.allowMultiple && this.attrvalues.length > 0) {
        return this.attrvalues.filter(attrVal => {
          const found = this.newValues.find(val => {
            return val.id === attrVal.attr_key_value.id
          })
          return !found
        })
      }
      return []
    }
  },
  methods: {
    initMultiValues () {
      if (this.attrvalues.length > 0) {
        const attrValues = JSON.parse(JSON.stringify(this.attrvalues))
        const values = attrValues.map(val => val.attr_key_value)
        values.sort((a, b) => {
          return (a.display_order - b.display_order)
        })
        this.newValues = values
      }
    },
    async saveValues () {
      const promises = []
      if (this.addedValues.length > 0) {
        const addPromises = this.addedValues.map(value => {
          const payload = this.buildSavePayload(value)
          return attrApi.attributeValue.post(payload)
        })
        promises.push(...addPromises)
      }
      if (this.removedValues.length > 0) {
        const removePromises = this.removedValues.map(value => {
          return attrApi.attributeValue.delete(value.id)
        })
        promises.push(...removePromises)
      }
      if (promises.length === 0) return
      try {
        const { fulfilled, rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        if (fulfilled.length > 0) {
          this.emitAlert(true, 'success', `${this.attr.name} has been successfully saved.`)
          this.$emit('refresh-values')
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    buildSavePayload (val) {
      const payload = {
        attr_id: this.attr.id,
        party_id: this.$route.query.id,
        start_dt: null,
        end_dt: null,
        attr_key_value_id: val.id,
        created_by: this.$store.getters.email
      }
      return payload
    },
    async getKeyValues () {
      await kv.keyValues.getList(this.attr.valid_value_key.id, 100, 0)
        .then(response => {
          this.keyValues = response.data
        }).catch(err => {
          this.handleError(err)
        })
    }
  }
}
</script>
