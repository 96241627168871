<template>
    <v-col cols="7" class="mt-6">
      <v-menu
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        nudge-left="25"
        nudge-top="25"
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="!readonly ? on : null"
            :label="attr.name"
            v-model="dateFormatted"
            prepend-inner-icon="mdi-calendar"
            :error-messages="(!readonly && attr.is_required && !dateFormatted && attrvalues.length === 0) ? `${attr.name} is required.` : []"
            outlined
            readonly
            @click="checkTimeSensitive"
            dense>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="newval"
          no-title
          @change="save">
        </v-date-picker>
      </v-menu>
    </v-col>
</template>
<script>
// Mixins
import { attrsMixin } from '@/mixins/attrs.js'
export default {
  name: 'DateInput',
  data () {
    return {
      newval: null
    }
  },
  mixins: [attrsMixin],
  computed: {
    dateFormatted () {
      if (this.attrvalue !== null) {
        return this.moment(this.attrvalue.value).format(this.$config.date_display)
      } else {
        return null
      }
    }
  }
}
</script>
