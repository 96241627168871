import PartyAttr from '@/axios/party-attribute-endpoint'

export const defaultStartDate = {
  methods: {
    getPartyDefaultStartDate ({ values = [], partyId = null }) {
      if (partyId) {
        return this.getPartyAttrValues(partyId)
      }
      if (values.length > 0) {
        const firstTimeSensitive = values.find(val => val.attr.is_time_sensitive)
        if (firstTimeSensitive) {
          return firstTimeSensitive.start_dt
        }
      }
      return null
    },
    async getPartyAttrValues (partyId, limit = 100, offset = 0) {
      const res = await PartyAttr.attributeValue.getList(partyId, limit, offset)
      const values = res.data
      return this.getPartyDefaultStartDate({ values })
    }
  }
}
