<template>
  <v-col cols="1" class="mx-2">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn tabindex="-1" v-on="on" icon small @click="$emit('activity')">
            <v-icon>mdi-history</v-icon>
          </v-btn>
        </template>
        <span>View History</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-show="attr.info" tabindex="-1" v-on="on" icon small @click="$emit('info')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ attr.info }}</span>
      </v-tooltip>
  </v-col>
</template>
<script>
export default {
  name: 'AttrTooltips',
  props: ['attr']
}
</script>
