<template>
    <v-col :cols="colSize">
      <v-text-field
        v-if="attr.max_length < 50 || attr.allow_multiple"
        class="pt-6"
        :label="(!attr.allow_multiple) ? attr.name : `${attr.name} Management`"
        outlined
        background-color="#fff"
        dense
        v-model="newval"
        autocomplete="off"
        :readonly="readonly"
        @change="save"
        @click="checkValue"
        @focus="checkTimeSensitive"
        :rules="[isValidInput]"
        :counter="attr.max_length > 0 && !attr.allow_multiple">
        <template v-slot:append>
          <v-icon v-if="attr.allow_multiple">
            mdi-dots-horizontal
          </v-icon>
        </template>
      </v-text-field>
      <v-textarea
        v-else
        :label="attr.name"
        v-model="newval"
        :counter="attr.max_length > 0 && !attr.allow_multiple"
        :readonly="readonly"
        @change="save"
        @click="checkValue"
        @focus="checkTimeSensitive"
        :rules="[isValidInput]"
        no-resize
        autocomplete="off"
        rows="3"
        outlined
        dense>
      </v-textarea>
    </v-col>
</template>
<script>
// Mixins
import { attrsMixin } from '@/mixins/attrs.js'
export default {
  name: 'InputBox',
  data () {
    return {
      newval: '',
      minLimitHit: false,
      maxLimitHit: false
    }
  },
  mixins: [attrsMixin],
  computed: {
    attrMax () {
      if (this.attr.max_length < 50 || this.attr.allow_multiple) {
        return (this.attr.max_length > 0) ? this.attr.max_length : 25
      }
      return (this.attr.max_length > 0) ? this.attr.max_length : null
    },
    colSize () {
      if (typeof this.noFocus !== 'undefined') {
        return null
      }
      if (
        this.attr.data_type.constant === 'INTEGER' ||
        this.attr.data_type.constant === 'NUMERIC' ||
        (this.attr.data_type.constant === 'STRING' && this.attr.allow_multiple)
      ) {
        return 7
      } else if (this.attr.data_type.constant === 'STRING') {
        return 8
      } else {
        return null
      }
    }
  },
  methods: {
    save (val) {
      if (this.minLimitHit || this.maxLimitHit) return
      if (this.attr.is_required && !val && !this.attr.is_time_sensitive) return
      this.$emit('save-attr', val)
    },
    isValidInput (val) {
      if (val) {
        if (this.attr.id === "VENDOR_ID" && this.attr.tenant === "awg") {
          if (val.length < 7) {
            this.minLimitHit = true
            return 'Left pad to start with 000. Ex: 0001234'
          } else {this.minLimitHit = false}
        }
        if (val.length > this.attrMax) {
          this.maxLimitHit = true
          return `Maximum ${this.attrMax} Characters`
        } else {this.maxLimitHit = false}
        return true
      }
      if (this.attr.is_required && this.newval !== null) {return `${this.attr.name} is required.`}
      return true
    }
  }
}
</script>
