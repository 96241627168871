var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar-title",
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-animation")]),
              _vm._v(" Attributes ")
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-expansion-panels",
        {
          attrs: {
            multiple: "",
            tile: "",
            accordion: "",
            flat: "",
            value: _vm.expanded
          }
        },
        _vm._l(_vm.partyAttributeGroups, function(attr_group) {
          return _c(
            "v-expansion-panel",
            { key: attr_group.id },
            [
              _c("v-divider"),
              _c(
                "v-expansion-panel-header",
                { staticClass: "grey lighten-3" },
                [_vm._v(" " + _vm._s(attr_group.name) + " ")]
              ),
              _c("v-divider"),
              _c(
                "v-expansion-panel-content",
                _vm._l(_vm.getAttributesForGroup(attr_group.id), function(
                  row,
                  index
                ) {
                  return _c(
                    "v-row",
                    { key: index, attrs: { align: "center", dense: "" } },
                    _vm._l(row, function(attr) {
                      return _c(
                        "v-col",
                        { key: attr.id, attrs: { cols: "3" } },
                        [
                          _c("AttrInput", {
                            ref: "attr-" + attr.id,
                            refInFor: true,
                            attrs: {
                              attr: attr,
                              attrvalue: _vm.getAttributeValueForAttribute(
                                attr
                              ),
                              attrvalues: _vm.getAttributeValuesForAttribute(
                                attr
                              ),
                              defaultStartDate: _vm.defaultStartDate,
                              hasWriteAccess: _vm.hasWriteAccess
                            },
                            on: {
                              "save-attr": _vm.saveAttribute,
                              "update-attr": _vm.updateAttribute,
                              "refresh-values": _vm.getPartyAttributes
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }