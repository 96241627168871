<template>
  <v-col>
    <v-checkbox
      :value="(attrvalue !== null) ? attrvalue.value : ''"
      :label="attr.name"
      true-value="True"
      false-value="False"
      v-model="newval"
      @change="save"
      @click="checkTimeSensitive"
      :readonly="readonly"
      :disabled="disabled">
    </v-checkbox>
  </v-col>
</template>
<script>
// Mixins
import { attrsMixin } from '@/mixins/attrs.js'
export default {
  name: 'CheckBox',
  data () {
    return {
      newval: ''
    }
  },
  mixins: [attrsMixin]
}
</script>
