var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c("v-checkbox", {
        attrs: {
          value: _vm.attrvalue !== null ? _vm.attrvalue.value : "",
          label: _vm.attr.name,
          "true-value": "True",
          "false-value": "False",
          readonly: _vm.readonly,
          disabled: _vm.disabled
        },
        on: { change: _vm.save, click: _vm.checkTimeSensitive },
        model: {
          value: _vm.newval,
          callback: function($$v) {
            _vm.newval = $$v
          },
          expression: "newval"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }